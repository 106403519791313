export const ExternalSwitches = {
  WEB_ATTRIBUTION_SURVEY: "web_attribution_survey_v2",
  WEB_EXTERNAL_LOGIN_OIDC_REDIRECT: "web_external_login_oidc_redirect_v1",
  WEB_EXTERNAL_TEACHER_SIGNUP_A11Y_LABELS: "web_external_teacher_signup_a11y_labels",
  WEB_EXTERNAL_PARENT_EMAIL_FIRST_LOGIN: "web_external_parent_email_first_login",
} as const;

export const LiteSwitches = {
  WEB_LITE_REDIRECT_EMAIL_VERIFICATION: "web_lite_redirect_email_verification_v3",
} as const;
export const TeacherSwitches = {} as const;

export const featureSwitches = Object.values({ ...ExternalSwitches, ...TeacherSwitches, ...LiteSwitches });

export const defaultFSValues = featureSwitches.reduce((acc, fs) => ({ ...acc, [fs]: "off" }), {});
